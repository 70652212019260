import { Typography } from '@material-ui/core'
import React from 'react'
import { colorPalette } from '../../theme'
import BranchIcon from '../icons/BranchIcon'
import CircleLeafIcon from '../icons/CircleLeafIcon'
import EcologyIcon from '../icons/EcologyIcon'
import GrowthIcon from '../icons/GrowthIcon'
import RecycleIcon from '../icons/RecycleIcon'
import AboutImage from '../images/axe-in-stump.jpeg'
import { FlexColumn, FlexRow } from './flexContainers'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const iconStyle = { 
    width: 40,
    height: 40,
    fill: colorPalette.mahogany,
};


const AboutPanel = props => {
  const hideImage = isWidthDown('sm', props.width)

    return (
        <FlexRow fullWidth style={{position: 'relative', overflow: 'hidden'}}>
        <FlexColumn  halfWidth={!hideImage} fullWidth={hideImage} padding={40}>
          <Typography variant='h5' style={{marginBottom: 0}}>
            100%
          </Typography>
          <Typography variant='h3' style={{marginBottom: 50}}>
            Handmade
          </Typography>
          <Typography variant='body1' style={{fontSize: '1.2rem'}} >
            I am a woodcraft artist living near the James River in Buckingham County, Virginia. This rustic area abounds with trees of all varieties, including many hardwoods, such as hickory, oak, chestnut, and birch. A year ago after a winter storm, I noticed how many trees had fallen naturally from the wind and weather, and it inspired me to repurpose some of that fallen timber.          </Typography>
          <Typography variant='body1' style={{marginTop: 10, fontSize: '1.2rem'}} >
            The mission of Peacefield Woodcrafts is to create useful art that not only expresses the innate beauty of these fallen trees, but also helps replace them. So, a percentage of each 5% purchase is donated, in your name to the OneTreePlanted.org. At your request, we will notify you the kind of tree and where it was planted.          </Typography>
          <FlexRow wrap spaceAround fullWidth marginTop={80} marginBottom={50}>
              <RecycleIcon style={iconStyle} /> 
              <CircleLeafIcon style={iconStyle}/> 
              <EcologyIcon style={iconStyle}/> 
              <GrowthIcon style={iconStyle}/>
          </FlexRow>
          <BranchIcon 
            style={{
              position: 'absolute', 
              left: -182,
              bottom: 192,
              width: 600,
              transform: 'rotate(-65deg)',
              opacity: .15,
              fill: colorPalette.orangeRed,
              zIndex: -1,
            }}
          />
      </FlexColumn>
        <FlexColumn 
          halfWidth
          style={{
            backgroundImage: `url(${AboutImage})`, 
            backgroundPosition: 'center', 
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            alignItems: 'flex-end',
            display: hideImage ? 'none' : 'block'
          }}
        >
          <div style={{height: '100%', width: '100%', background: 'rgba(100,100,100,.5)', padding: 20}}>
            <div 
              style={{
                width: '100%', 
                height: '100%', 
                border: `2px solid ${colorPalette.white}`,
                borderRadius: 50,
                padding: '7px 7px',
              }} 
            >
              <div 
                style={{
                  width: '100%', 
                  height: '100%', 
                  border: `1px solid ${colorPalette.white}`,
                  borderRadius: 40,
                }} 
              />
            </div>
          </div>
        </FlexColumn>
      </FlexRow>
    );
};

AboutPanel.propTypes = {
    
};

export default withWidth()(AboutPanel);