import React from 'react'
import AboutPanel from '../components/AboutPanel'
import MainLayout from '../components/layout'
import ProductsPanel from '../components/ProductsPanel'
import SEO from '../components/seo'

const IndexPage = () => {
  // TODO:
  // > Lighten up favicon
  // > Replace Lorem Ipsum?
  // > Use tree icon

  // LONG TERM:
  // > FOUC?
  // > set-up peacefield.com email

  return(
    <MainLayout>
      <SEO title="Home" />
      <AboutPanel />
      <ProductsPanel />
    </MainLayout>
  )
}
export default IndexPage
