
import React from 'react';
import PropTypes from 'prop-types';
import { FlexColumn, FlexRow } from './flexContainers';
import { Button, Divider, Typography } from '@material-ui/core';
import { colorPalette } from '../../theme';
import CandleIcon from '../icons/CandleIcon';
import ProductCard from './ProductCard';
import CandleProduct from '../images/candle-product.jpeg'
import HouseProduct from '../images/fairy-house.jpeg'
import CollageProduct from '../images/collage-product.jpg'
import ProductBgImage from '../images/mountain-field.jpg';

const featProducts = [
    {
        img: CandleProduct,
        name: 'Live Edge Candle Holders',
        description: 'One of a kind wooden candle holders hewn from naturally fallen trees.',
        altText: 'Wooden Candle Holder with Candle',
    },
    {
        img: HouseProduct,
        name: 'Gnome House Candle Holders',
        description: 'Unique wooden candle holders carved from natural logs to look like a small gnome or fairy house.',
        altText: 'Gnombe house made from wooden log',
    },
    // {
    //     img: CollageProduct,
    //     name: 'Collage Art',
    //     description: 'Custom-made collages made from old news papers and magazine set on a live-edge wood panels',
    //     altText: 'Collage of pictures',
    // },
];

const ProductsPanel = props => {

    return (
        <div id='product-panel' style={{
            backgroundImage: `url(${ProductBgImage})`,
            backgroundPosition: 'center', 
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            position: 'relative', 
    }}>
        <FlexColumn 
            fullWidth 
            style={{
                alignItems: 'center', 
                padding: 60, 
                background: 'rgba(50,50,50,.9)',
                textAlign: 'center',
            }}
        >
            <Typography variant='h3' style={{color: colorPalette.white}}>
                Featured Woodcrafts
            </Typography>
            <FlexRow spaceAround style={{alignItems: 'center', width: 200, marginTop: 30, marginBottom: 30}}>
                <Divider style={{width: 50, height: 2, backgroundColor: colorPalette.white}} />
                <CandleIcon style={{width: 35, height: 35, marginTop: -8, fill: colorPalette.white}}/>
                <Divider style={{width: 50, height: 2, backgroundColor: colorPalette.white}} />
            </FlexRow>
            <FlexRow wrap fullWidth spaceAround>
                {
                    featProducts.map(p => 
                        <div style={{width: '30%', minWidth: 300, maxWidth: 400, marginTop: 20}}>
                            <ProductCard name={p.name} description={p.description} img={p.img} />
                        </div>
                    )
                }
            </FlexRow>
            <Button target='_blank' href='https://www.etsy.com/shop/PeacefieldWoodcraft' variant='contained' color='secondary' size='large' style={{marginTop: 60}}>
                View all my woodcrafts on Etsy
            </Button>
        </FlexColumn>
        </div>
    );
};

ProductsPanel.propTypes = {
    
};

export default ProductsPanel;