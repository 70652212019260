import * as React from "react"
import { colorPalette } from "../../theme"

function CandleIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={153.939}
      height={153.939}
      viewBox="0 0 153.939 153.939"
      fill={colorPalette.orangeRed}
      {...props}
    >
      <path d="M88.473 61.606C104.834 41.42 89.25 14.987 71.413 0c3.335 16.31-23.724 39.24-6.225 61.636-22.386 1.166-39.105 5.491-39.105 10.712v70.541c0 6.097 22.783 11.05 50.883 11.05 28.111 0 50.892-4.948 50.892-11.05v-70.54c-.001-5.238-16.851-9.604-39.385-10.743zM76.965 81.172c-32.101 0-48.654-6.184-48.654-8.823 0-2.654 13.683-7.47 38.682-8.545 1.723 1.888 3.67 3.773 6.119 5.645-2.488-2.249-4.074-4.457-5.084-6.646l-.583-1.294C63.59 50.532 75.032 39.925 77.24 26.89c7.819 12.657 14.391 24.879 9.244 35.369l-.341.651a20.665 20.665 0 01-2.896 4.096c1.248-1.043 2.309-2.132 3.344-3.217 25.228 1.041 39.025 5.906 39.025 8.571.014 2.628-16.546 8.812-48.651 8.812z" />
      <path d="M76.973 51.062c-1.23 0-2.225.998-2.225 2.23v19.057a2.225 2.225 0 002.225 2.224 2.226 2.226 0 002.229-2.224V53.292c0-1.232-.997-2.23-2.229-2.23z" />
    </svg>
  )
}

export default CandleIcon
