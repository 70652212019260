import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import { colorPalette } from '../../theme';
import { FlexColumn, FlexRow } from './flexContainers';

const ProductCard = ({name, description, img, altText}) => {
    return (
        <Card style={{backgroundColor: colorPalette.white, margin: 2.5}} elevation={2} >
            <CardMedia
                component='img'
                alt={altText}
                image={img}
            />
            <CardContent style={{padding: 20}} >
                <FlexColumn fullWidth style={{alignItems: 'center', textAlign: 'center'}} >
                    <Typography gutterBottom variant="h5" component="h2" style={{height: 64}} >
                        {name}
                    </Typography>
                    <Typography variant="body2" color="primary" style={{fontSize: '1rem', lineClamp: 3, height: 66}}>
                        {description}
                    </Typography>
                </FlexColumn>
            </CardContent>
            <CardActions>
                <FlexColumn fullWidth style={{alignItems: 'center', marginBottom: 20}} >
                    <Button target='_blank' href='https://www.etsy.com/shop/PeacefieldWoodcraft' size="small" variant='contained' color="primary">
                        View more on Etsy
                    </Button>
                </FlexColumn>
            </CardActions>
            {/* <FlexRow fullWidth alignCenter >
                    <div style={{
                        width: 200,
                        height: 200,
                        backgroundImage: `url(${img})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        borderRadius: 5,
                    }}
                />
            </FlexRow> */}

        </Card>
    );
};

ProductCard.propTypes = {
    
};

export default ProductCard;